import {
    Box,
    Container,
    Grid,
    MenuItem,
    TextField,
    Typography
} from '@mui/material';
import { ApexOptions } from 'apexcharts';
import { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useSearchParams } from 'react-router-dom';
import channels from 'src/mock/Channel';
import { DashboardRepository } from '../data/DashboardRepository';
import { GetGuidesReport } from '../domain/GetGuidesReports';
import { GuideReportData } from './models/GuideReportData';

const Reports = () => {
    const repository = new DashboardRepository();
    const getGuidesReport = new GetGuidesReport(repository);
    const [channelItems, setChannelItems] = useState<JSX.Element[]>([]);
    const [chartOptions, setChartOptions] = useState<ApexOptions>();
    const [data, setData] = useState<GuideReportData>();
    const [searchParams, setSearchParams] = useSearchParams();
    const channel = searchParams.get('channel') || 'all';

    const fetchData = async (channelValue: string) => {
        const result = await getGuidesReport.execute(channelValue);

        const options: ApexOptions = {
            series: [
                {
                    name: 'No Impreso',
                    data: result.notPrinted
                },
                {
                    name: 'Impreso',
                    data: result.printed
                },
                {
                    color: 'gray',
                    name: 'Sin Guía',
                    data: result.notGuides
                },
                {
                    color: 'red',
                    name: 'Cancelado',
                    data: result.cancelled
                }
            ],
            chart: {
                type: 'bar',
                height: 350
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '55%'
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                show: true,
                width: 1,
                colors: ['transparent']
            },
            xaxis: {
                labels: {
                    show: true
                },
                type: 'category',
                categories: result.labels
            },
            yaxis: {
                title: {
                    text: 'Número de guías'
                }
            },
            fill: {
                opacity: 1
            }
        };
        setChartOptions(options);
        setData(result);
    };

    async function getData() {
        const items: JSX.Element[] = channels.map((item) => (
            <MenuItem key={item.name} value={item.value}>
                {item.name}
            </MenuItem>
        ));
        setChannelItems(items);
    }

    useEffect(() => {
        getData();
        fetchData(channel);
    }, []);

    const handleFilterByChannel = async (e) => {
        const channelValue = e.target.value;
        setSearchParams({
            channel: channelValue
        });
        fetchData(channelValue);
    };

    return (
        <>
            <Container
                maxWidth="xl"
                sx={{
                    width: '100%',
                    borderRadius: 2,
                    backgroundColor: 'white'
                }}
            >
                <Box
                    sx={{
                        borderRadius: 2,
                        alignItems: 'start'
                    }}
                >
                    <Box
                        padding={2}
                        display="grid"
                        gridTemplateColumns="repeat(1fr)"
                        gap="20px"
                        m={1}
                    >
                        <Box
                            gridColumn="span 2"
                            display="flex"
                            alignItems="start"
                            justifyContent="start"
                            sx={{ borderRadius: 1 }}
                        >
                            <Box display="flex" justifyContent="left">
                                <Box>
                                    <Typography
                                        variant="h4"
                                        fontWeight="bold"
                                        sx={{
                                            color: '#151D48',
                                            fontSize: 28,
                                            mt: 1,
                                            mb: 1
                                        }}
                                    >
                                        Reportes
                                    </Typography>

                                    <Typography
                                        variant="h5"
                                        fontWeight={'normal'}
                                        sx={{ color: '#151D48' }}
                                    >
                                        Resumen de guías creadas.
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Container>

            <Container
                maxWidth="xl"
                sx={{
                    width: '100%',
                    borderRadius: 2,
                    backgroundColor: 'white'
                }}
            >
                <Grid justifyContent="center" alignItems="stretch">
                    <Box display="flex" pt={2}>
                        <TextField
                            id="outlined-select-currency"
                            select
                            label="Filtro por canal"
                            defaultValue={channel}
                            sx={{
                                width: '15%',
                                ml: 1
                            }}
                            size="small"
                            onChange={handleFilterByChannel}
                        >
                            {channelItems}
                        </TextField>
                    </Box>
                    <Box mt="1em" />
                </Grid>
                {data && (
                    <ReactApexChart
                        options={chartOptions}
                        series={chartOptions.series}
                        type="bar"
                        height={450}
                    />
                )}
            </Container>
        </>
    );
};

export default Reports;
